import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import ProcessCard from "../../components/cards/ProcessCard"
import FeatureCard from "../../components/cards/FeatureCard"
import CapabilityCard from "../../components/cards/CapabilityCard"
import MomentCard from "../../components/cards/MomentCard"

import Toggle from "../../components/controls/Toggle"
import Collapsed from "../../components/controls/Collapsed"
import CanvasToolbar from "../../components/canvas/CanvasToolbar"
import Canvas from "../../components/canvas/Canvas"


import { useState } from "react"



export default function Pages( {data}) {

const [isGrid, toggleGrid] = useState(true);
const [isFeatures, toggleFeatures] = useState(true);
const [isNeeds, toggleNeeds] = useState(true);
const [isProcess, toggleProcess] = useState(true);
const [isCapabilities, toggleCapabilities] = useState(true);

const [isOptions, toggleOptions] = useState(false);

const columnWidth = 320;
const horPadding = 0;
const verPadding = 12;

return (
  <>
  <CanvasLayout>
  {isLoggedIn() ? (

<div>
    
    <CanvasToolbar>    
    <div><a href={data.airtable.data.Path}><span className="">&lt; Back to {data.airtable.data.Name}</span></a></div>
    </CanvasToolbar>
    
 <Canvas title={data.airtable.data.Name} introduction={data.airtable.data.Introduction}>  
    
    <div className="grid grid-cols-3 gap-8 m-16">
    
    
    {data.airtable.data.BlueprintsL1.map((BlueprintsArray, index) => (
      <>
      {BlueprintsArray.data.Experiences.map((ExperienceArray, index) => (
        <>
       
        {ExperienceArray.data.Moments.map((MomentsArray, index) => (
          <div className="relative">
            <div className="absolute top left-52 h-10 w-10 rounded-full bg-yellow flex items-center justify-center font-bold">{MomentsArray.data.Order}</div>

            <img src={MomentsArray.data.HeroImageURL}
            alt={MomentsArray.data.Name}
            className="h-40 ml-16 mb-8"
            />
          <div className="bg-blue/20 rounded-xl p-8">
          <Type type="h5" nopadding="yes">{MomentsArray.data.Name}</Type>
          <Type type="p6">{MomentsArray.data.Introduction}</Type>
                  
                    
            <div className="grid grid-cols-2 gap-4 mb-12 mt-4">
              {MomentsArray.data.NeedsWhat.map((NeedsWhatArray, index) => (
                <>
                <div className="bg-white rounded-xl p-8">
                  <Type type="p5">{NeedsWhatArray.data.Name}</Type>
                  <div className="border-t pt-8">
                  <div className="mb-8">
                  
                  <Collapsed title="Action Needs">
                  {NeedsWhatArray.data.NeedsHow.map((NeedsHowArray, index) => (
                    
                    <div className="bg-grey/30 p-8 text-center mb-4"><Type type="p6" nopadding="yes">{NeedsHowArray.data.Name}</Type></div>
                  ))}
                  
                  </Collapsed>
                  </div>                  

  
                  <div className="mb-8">
                  <Collapsed title="Notes">
                  {NeedsWhatArray.data.Notes.map((NotesArray, index) => (
                  <div className={`rounded-xl bg-${NotesArray.data.NoteColour} p-8`}>
                    <img
                      src={NotesArray.data.NoteIcon}
                      alt={NotesArray.data.NoteType}
                      className="w-8 mb-8"
                      />
                   <Type type="p6" nopadding="yes">{NotesArray.data.Note}</Type>
                  </div>
                ))}                 
                  </Collapsed>
                  </div>
                  </div>
                </div>
                </>
              ))}
            </div>
            
          </div>
          </div>
         
          ))}
        </>
      ))}
      </>
    ))}
      
    
    </div>
    
    </Canvas>

</div>

    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}

  </CanvasLayout>
    </>

  )
}

export const Head = () => <title>Framework</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Projects" },
    data: { NeedsPath: { eq: $Path } }) {
      data {
        Name
        Path
        BlueprintsL1
        {data {
          Experiences {data {
            Moments {data{
              Name
              Order
              Introduction
              HeroImageURL
              NeedsWhat {data {
                Name
                NeedsHow {
                  data {
                    Name
                  }
                }
                Notes {data {
                  Note
                  NoteIcon
                  NoteColour
                }}
              }}
            }}
          }}
        }}
      }
    }
  }
`
